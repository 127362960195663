<template>
  <div style="margin-top: 3px">
    <remoteTooltip
      :path="'serve.php/'+currentTable.testInfoLink" 
      :additionalAttributes="additionalAttributes"
      :dialogAttributes="{}"
    >
      <a
        :href="
          currentTable.testInfoLink +
          this.row.n_id +
          '&n_id_key=' +
          this.row.n_id_key
        "
      >
        <v-icon>mdi-information</v-icon>
      </a>
    </remoteTooltip>
  </div>
</template>
<script>
import remoteTooltip from "@/commonComponents/remoteTooltip.vue";
export default {
  components: { remoteTooltip },
  props: ["row", "currentTable"],
  computed: {
    additionalAttributes: function () {
      let additionalAttributes = {
        n_id: this.row.n_id,
        n_id_key: this.row.n_id_key,
        function: "getInfoMini",
      };
      return additionalAttributes;
    },
  },
};
</script>